@import "../../../variables.scss";

.toolBarStyle {
    text-align: left;
}

.titleStyle {
    margin: 1rem !important;
    display: inline;
}

.tabsBtnCreate {
    right: 0.5rem !important;
    background-color: $lightBlue !important;
    color: $white !important;
    display: inline-block;
    float: right;
    position: relative;
}

.tabsBtnCreate:hover {
    background-color: $lightGrey !important;
    color: $black !important;
    cursor: pointer !important;
}

.wrapper {
    margin-top: 2rem;
    max-height: calc(100vh - 438px);
    overflow-y: auto;
    position: relative;

    table {
        border: 1px solid rgba(224, 224, 224, 1);

        tr {
            & > td {
                padding: 0.4rem 2rem;
                color: var(--dark-silver);
                box-sizing: border-box;
                svg {
                    fill: var(--transparent-blue);
                    font-size: 1rem !important;
                }
            }

            &:hover {
                td {
                    color: var(--main-color);
                }
            }
        }
    }
}

table,
thead,
tbody,
td,
tr {
    border-collapse: collapse;
}

.table {
    border-collapse: collapse;
    width: 100%; /* Ensure the table takes full width */
}

.thead {
    border: none !important; //rgba(224, 224, 224, 1);
    position: sticky;
    top: -0.5px;
    z-index: 10 !important; /* Ensure the thead stays above tbody */
    background-color: white;
}

.tbody {
    width: 100%; /* Make tbody take full width */
    display: block; /* Allow scrolling on tbody */
    overflow-y: auto; /* Enable scrolling on tbody */
    position: relative;
    z-index: 1 !important;
}

.tbody tr {
    width: 100%;
    table-layout: fixed; /* Keeps columns aligned */
}

.toolbarButtonsGroup {
    display: flex;
}

.toolbarButtons {
    background-color: $lightGrey !important;
    color: $black !important;
}

// RESPONSIVE

@media only screen and (max-width: 900px) {
    .wrapper {
        max-height: none;
    }
}
