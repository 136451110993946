@import "../../../variables.scss";

.wrapper {
    clear: both;
    min-height: 40px;
    margin-bottom: 1em;
}

.title {
    color: red;
}

.buttons {
    float: right;
    display: flex;
    gap: $gap;
}
