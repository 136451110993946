@import "../../../../variables.scss";

.wrapper {
    clear: both;
    min-height: 40px;
    margin-bottom: 1em;
}

.title {
}

.buttons {
    float: right;
    display: flex;
    gap: $gap;
}
