.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #00000029;
	backdrop-filter: blur(1px);
}

.box > form {
	flex: 1;
}

.box > span {
	margin-left: 0;
	margin-right: auto;
}
