.formWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.formContainer {
    width: 100%;
    max-width: 100%;
}
